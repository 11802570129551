// Libraries
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
// Compoents
import Button, { ButtonVariant } from "@Components/Button/Button"
import CookiesSettings from "./components/cookies-settings/CookiesSettings"
import Modal from "@Components/Modal/Modal"
import { Text, Title } from "@Components/_Typography"
// Styles
import * as styles from "./Cookies.module.scss"
// Types
import { CookiesSettingsAccordionProps } from "./components/cookies-settings-accordion/CookiesSettingsAccordion"
import { TitleTag, TitleVariant } from "@Components/_Typography/Title/Title"

const INITIAL_SETTINGS = {
  technical: true,
  analytics: true,
  marketing: true,
}

interface CookiesProps {
  closeCookieHandler: () => void
}

const Cookies = (props: CookiesProps) => {
  const { closeCookieHandler } = props

  const [showSettings, setShowSettings] = useState(false)
  const [cookieSettings, setCookieSettings] = useState(INITIAL_SETTINGS)

  const { t } = useTranslation()

  const toggleSettingsHandler = () => setShowSettings(!showSettings)
  const toggleCookie = (option: "analytics" | "marketing") =>
    setCookieSettings({ ...cookieSettings, [option]: !cookieSettings[option] })

  const cookieOptions: CookiesSettingsAccordionProps[] = [
    {
      id: "technical",
      isChecked: cookieSettings.technical,
      title: t("cookiesBar.technicalTitle"),
      description: t("cookiesBar.technicalDescription"),
    },
    {
      id: "analytics",
      isChecked: cookieSettings.analytics,
      title: t("cookiesBar.analyticalTitle"),
      description: t("cookiesBar.analyticalDescription"),
      toggleHandler: () => toggleCookie("analytics"),
    },
    {
      id: "marketing",
      isChecked: cookieSettings.marketing,
      title: t("cookiesBar.marketingTitle"),
      description: t("cookiesBar.marketingDescription"),
      toggleHandler: () => toggleCookie("marketing"),
    },
  ]

  const acceptAllHandler = () => {
    localStorage.setItem("technical", "true")
    localStorage.setItem("marketing", "true")
    localStorage.setItem("analytics", "true")
    return closeCookieHandler()
  }

  const acceptChoosedHandler = () => {
    localStorage.setItem("technical", cookieSettings.technical.toString())
    localStorage.setItem("marketing", cookieSettings.marketing.toString())
    localStorage.setItem("analytics", cookieSettings.analytics.toString())
    return closeCookieHandler()
  }

  return (
    <Modal closeHandler={closeCookieHandler} wrapperClasses={styles.modal}>
        <Title tag={TitleTag.h2} variant={TitleVariant.standard}>Cookies</Title>
      <Text innerHtml={t("cookiesBar.description") ?? ""} />
      {showSettings && <CookiesSettings cookieOptions={cookieOptions} />}
      <div className={styles.cookieOptions}>
        <Button
          variant={ButtonVariant.OutlineWithBorder}
          onClick={!showSettings ? toggleSettingsHandler : acceptChoosedHandler}
        >
          {!showSettings ? t("cookiesBar.settings") : t("cookiesBar.save")}
        </Button>
        <Button onClick={acceptAllHandler}>{t("cookiesBar.acceptAll")}</Button>
      </div>
    </Modal>
  )
}

export default Cookies
