// extracted by mini-css-extract-plugin
export var languages = "Navbar-module--languages--f8437";
export var navbar = "Navbar-module--navbar--991ef";
export var navbarButton = "Navbar-module--navbarButton--13f7c";
export var navbarExpanded = "Navbar-module--navbarExpanded--ebde3";
export var navbarLogo = "Navbar-module--navbarLogo--fc5b1";
export var navbarMenu = "Navbar-module--navbarMenu--5e1b2";
export var navbarMenuItem = "Navbar-module--navbarMenuItem--dcc33";
export var navbarMenuService = "Navbar-module--navbarMenuService--05f11";
export var navbarScrolled = "Navbar-module--navbarScrolled--b8d74";
export var navbarSubmenu = "Navbar-module--navbarSubmenu--aa042";
export var navbarSubmenuContainer = "Navbar-module--navbarSubmenuContainer--adb79";
export var navbarSubmenuItem = "Navbar-module--navbarSubmenuItem--579fa";