import React, { useEffect, useState } from "react"
import Footer from "./components/Foooter/Footer"
import Navbar from "./components/Navbar"
import Cookies from "./components/Cookies/Cookies"

const Layout = (props: { children: any }) => {
  const [showCookie, setShowCookie] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setShowCookie(
          !!!localStorage?.getItem("technical") ||
            !!!localStorage?.getItem("marketing") ||
            !!!localStorage?.getItem("analytics")
        ),
      5000
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      <Navbar />
      <div className="top-space"></div>
      {props.children}
      {showCookie && (
        <Cookies closeCookieHandler={() => setShowCookie(false)} />
      )}
      <Footer />
    </>
  )
}

export default Layout
