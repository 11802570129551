import React from "react"
import classNames from "classnames"
// Types
import { Common, FontColors } from "../../../types/main"
// Styles
import * as styles from "./Text.module.scss"

interface TextProps extends Common {
  as?: "p" | "span"
  color?: FontColors
  style?: React.CSSProperties
  innerHtml?: string
}

const Text = ({
  children,
  classes,
  as: Tag = "p",
  color,
  style,innerHtml
}: TextProps) => (
  <Tag
    className={classNames(styles.text, classes)}
    data-color={color}
    style={style}
    dangerouslySetInnerHTML={innerHtml ? { __html: innerHtml } : undefined}
  >
    {children}
  </Tag>
)

export default Text
