// Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import CookiesSettingsAccordion, {
    CookiesSettingsAccordionProps,
} from '../cookies-settings-accordion/CookiesSettingsAccordion';
// Styles
import * as styles from './CookiesSettings.module.scss';

interface CookiesSettingsProps {
    cookieOptions: CookiesSettingsAccordionProps[];
}

const CookiesSettings = (props: CookiesSettingsProps) => {
    const { cookieOptions } = props;
    const { t } = useTranslation('cookiesBar');

    return (
        <div className={styles.settings}>
            <p className={styles.settingsText}>{t('info')}</p>
            {cookieOptions.map((item) => (
                <CookiesSettingsAccordion key={item.title} {...item} />
            ))}
        </div>
    );
};

export default CookiesSettings;
