import React from "react"
import { I18nextProvider } from "react-i18next"
import i18n from "i18next"
// Components
import Layout from "./src/components/layout/Layout"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "@Styles/main.scss"

i18n.init({
  lng: "pl",
  resources: {
    en: {
      translation: require("./src/locales/en/en.json"),
    },
    pl: {
      translation: require("./src/locales/pl/pl.json"),
    },
  },
  fallbackLng: "en",
  initImmediate: false,
  interpolation: { escapeValue: false },
})

export const wrapPageElement = ({ element, props }) => {
  const currentLocale = props.pageContext.language

  i18n.language !== currentLocale && i18n.changeLanguage(currentLocale)

  return (
    <I18nextProvider i18n={i18n}>
      <Layout>{element}</Layout>
    </I18nextProvider>
  )
}
