exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-aktualnosci-index-tsx": () => import("./../../../src/pages/aktualnosci/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-index-tsx" */),
  "component---src-pages-do-pobrania-tsx": () => import("./../../../src/pages/do-pobrania.tsx" /* webpackChunkName: "component---src-pages-do-pobrania-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-obrobka-materialow-tsx": () => import("./../../../src/pages/obrobka-materialow.tsx" /* webpackChunkName: "component---src-pages-obrobka-materialow-tsx" */),
  "component---src-pages-oferta-bankowozy-tsx": () => import("./../../../src/pages/oferta/bankowozy.tsx" /* webpackChunkName: "component---src-pages-oferta-bankowozy-tsx" */),
  "component---src-pages-oferta-brygadowki-tsx": () => import("./../../../src/pages/oferta/brygadowki.tsx" /* webpackChunkName: "component---src-pages-oferta-brygadowki-tsx" */),
  "component---src-pages-oferta-chlodnie-tsx": () => import("./../../../src/pages/oferta/chlodnie.tsx" /* webpackChunkName: "component---src-pages-oferta-chlodnie-tsx" */),
  "component---src-pages-oferta-dziewiecio-osobowe-tsx": () => import("./../../../src/pages/oferta/dziewiecio-osobowe.tsx" /* webpackChunkName: "component---src-pages-oferta-dziewiecio-osobowe-tsx" */),
  "component---src-pages-oferta-kontenery-tsx": () => import("./../../../src/pages/oferta/kontenery.tsx" /* webpackChunkName: "component---src-pages-oferta-kontenery-tsx" */),
  "component---src-pages-oferta-pojazdy-specjalne-tsx": () => import("./../../../src/pages/oferta/pojazdy-specjalne.tsx" /* webpackChunkName: "component---src-pages-oferta-pojazdy-specjalne-tsx" */),
  "component---src-pages-oferta-przewoz-niepelnosprawnych-tsx": () => import("./../../../src/pages/oferta/przewoz-niepelnosprawnych.tsx" /* webpackChunkName: "component---src-pages-oferta-przewoz-niepelnosprawnych-tsx" */),
  "component---src-pages-oferta-skrzynie-tsx": () => import("./../../../src/pages/oferta/skrzynie.tsx" /* webpackChunkName: "component---src-pages-oferta-skrzynie-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-praca-index-tsx": () => import("./../../../src/pages/praca/index.tsx" /* webpackChunkName: "component---src-pages-praca-index-tsx" */),
  "component---src-pages-serwis-tsx": () => import("./../../../src/pages/serwis.tsx" /* webpackChunkName: "component---src-pages-serwis-tsx" */),
  "component---src-theme-article-article-tsx": () => import("./../../../src/theme/Article/Article.tsx" /* webpackChunkName: "component---src-theme-article-article-tsx" */)
}

