export interface Common {
  id?: string
  classes?: string
  children?: any
}

export enum FontColors {
  dark = "dark",
  light = "light",
  primary = "primary",
  gray = "gray",
}

export enum Language {
  PL = "pl_PL",
  EN = "en",
}

export interface FormField {
	value: any
	errorMessage: string
	required: boolean
	isValid?: boolean
}

export type FormKeys<FormField extends Record<string, any>> = {
	[K in keyof FormField]: FormField[K];
};

export enum FormSteps {
  Form,
  Sending,
  Success,
  Error,
}
