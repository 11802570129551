import React from 'react';
// Libraries
import ContentWraper from './ContentWraper';
// Types
import { Common } from '@Types/main';

interface ModalProps extends Common {
  isOpen?: boolean;
  closeHandler: () => void;
  wrapperClasses?: string;
}

const Modal = (props: ModalProps) => {
  const { children, closeHandler, wrapperClasses, isOpen } = props;

  return (
    <div className="modal" data-is-open={isOpen}>
      <ContentWraper classes={wrapperClasses}>
        <button className="modal__button" onClick={closeHandler} type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M0.22944 19.7702C0.377657 19.9184 0.574703 20 0.784265 20C0.993828 20 1.19088 19.9184 1.33909 19.7702L10 11.1094L18.6609 19.7702C18.8091 19.9184 19.0062 20 19.2157 20C19.4253 20 19.6223 19.9184 19.7706 19.7702C20.0765 19.4643 20.0765 18.9665 19.7706 18.6606L11.1097 9.99981L19.7706 1.33902C20.0765 1.03311 20.0765 0.535346 19.7706 0.229419C19.4646 -0.0764728 18.9668 -0.0764728 18.6609 0.229419L10 8.89017L1.33909 0.229419C1.03317 -0.0764728 0.535359 -0.0764728 0.22944 0.229419C-0.0764799 0.535346 -0.0764799 1.03311 0.22944 1.33902L8.89035 9.99981L0.22944 18.6606C-0.0764799 18.9665 -0.0764799 19.4643 0.22944 19.7702Z"
              fill="black"
            />
          </svg>
        </button>
        {children}
      </ContentWraper>
    </div>
  );
};

export default Modal;
