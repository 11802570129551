// Libraries
import React, { useMemo, useState } from 'react';
// Styles
import * as styles from './CookiesSettingsAccordion.module.scss';

export interface CookiesSettingsAccordionProps {
    id: string;
    title: string;
    description: string;
    isChecked: boolean;
    toggleHandler?: () => void;
}

const CookiesSettingsAccordion = (props: CookiesSettingsAccordionProps) => {
    const { id, title, description, isChecked, toggleHandler } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const isDisabled = useMemo(() => typeof toggleHandler === 'undefined', [toggleHandler]);

    const toggleExpandHandler = () => setIsExpanded(!isExpanded);

    return (
        <div className={styles.accordion}>
            <h5 className={styles.accordionTitle} onClick={toggleExpandHandler}>
                {title}
            </h5>
            <label className={styles.accordionInput} data-is-checked={isChecked} data-is-disabled={isDisabled}>
                <input
                    type="checkbox"
                    name={id}
                    id={id}
                    checked={isChecked}
                    onChange={toggleHandler}
                    disabled={isDisabled}
                />
            </label>
            <button
                data-is-expanded={isExpanded}
                className={styles.accordionExpand}
                onClick={toggleExpandHandler}
            ></button>
            {isExpanded && <p>{description}</p>}
        </div>
    );
};

export default CookiesSettingsAccordion;
