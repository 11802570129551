// Libraries
import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
// Icons
import YouTube from "@Icons/social/YouTube"
import Facebook from "@Icons/social/Facebook"
import LinkedIn from "@Icons/social/LinkedIn"
// Components
import Container from "@Components/Container/Container"
// Types
import { Tag } from "@Types/tags"
// Styles
import * as styles from "./Footer.module.scss"
import Button, { ButtonTag, ButtonVariant } from "@Components/Button/Button"

const Footer = () => {
  const { t, i18n } = useTranslation()

  return (
    <Container as={Tag.Footer} classes={styles.wrapper}>
      <Link to="/">
        <StaticImage
          src="../../../../assets/images/light-logo.png"
          alt="Innovate tech solutions"
          loading="lazy"
          placeholder="blurred"
          formats={["auto", "webp"]}
          objectFit="contain"
          width={251}
          height={51}
        />
      </Link>

      <div className={styles.links}>
        <div className={styles.linksItemLarge}>
          <p className={styles.text}>
            <strong>Gruau Polska sp. z o.o.</strong> <br />
            Daszyna 29a,
            <br /> 99-107 Daszyna
            <br />
            <br />
            <a href="tel:+48243890900">Tel: 24 389 09 00</a> <br />
            <a href="mailto:gruau@gruau.pl">gruau@gruau.pl</a> <br />
            Woj. łódzkie <br /> NIP: 7752323318
          </p>
        </div>

        <div className={styles.linksItem}>
          <h4 className={styles.title}>Gruau</h4>
          <Link className={styles.text} to={t("routes.about.link")}>
            {t("routes.about.text")}
          </Link>
          <Link className={styles.text} to={t("routes.offer.link")}>
            {t("routes.offer.text")}
          </Link>
          <Link className={styles.text} to={t("routes.work.link")}>
            {t("routes.work.text")}
          </Link>
          <Link className={styles.text} to={t("routes.service.link")}>
            {t("routes.service.text")}
          </Link>
          <Link className={styles.text} to={t("routes.contact.link")}>
            {t("routes.contact.text")}
          </Link>
        </div>
        <div className={styles.linksItem}>
          <h4 className={styles.title}>{t("footer.newses.title")}</h4>
          <Link className={styles.text} to={t("routes.newses.link")}>
            {t("routes.newses.text")}
          </Link>
        </div>
        <div className={styles.linksItem}>
          <h4 className={styles.title}>{t("footer.info.title")}</h4>
          <Link className={styles.text} to={t("routes.policy.link")}>
            {t("routes.policy.text")}
          </Link>
        </div>
        {/* <div className={styles.linksItem}>
          <h4 className={styles.title}>Social media</h4>
          <div className={styles.social}>
            <a className={styles.text} href="/">
              <Facebook />
            </a>
            <a className={styles.text} href="/">
              <YouTube />
            </a>
            <a className={styles.text} href="/">
              <LinkedIn />
            </a>
          </div>
        </div> */}
      </div>
      <Button
        as={ButtonTag.InternalLink}
        variant={ButtonVariant.Light}
        href={t("routes.download.link") ?? ""}
        classes={styles.button}
      >
        {t("pages.download.title")}
      </Button>

      <p className={styles.bottom}>
        <small>Copyright &copy; 2024 All rights reserved</small><br />
        <small>Designed & made by: <a href="https://www.innovate-ts.com" target="_blank" rel="noopener noreferrer">ITS</a></small>
      </p>
    </Container>
  )
}

export default Footer
